<script>
import * as d3 from 'd3';

export default {
  name: "Gmap",
  props: ["map_data"],
  data() {
    return {
      map: null,
    }
  },
  mounted() {
    (async () => {
      await initializeAfterScriptLoad.call(this);
      updateMap(this.map, this.map_data)
    })();
  },

  render() {
    return (
      <div class="Gmap">
        <div class="map_cont">
          <div id="map"/>
        </div>
        <h3 class="no-map-banner p-4">{this.map_data && !this.map_data.length ? 'No map data' : ''}</h3>
      </div>
    )
  },
  watch: {
    map_data() {
      if (this.map) updateMap(this.map, this.map_data)
    }
  }
};

function initializeAfterScriptLoad() {
  loadScript();
  return new Promise(resolve => {
    const check = () => {
      if (window.google && window.google.maps) {
        this.map = initializeMap();
        resolve()
      }
      else setTimeout(check, 300)
    }
    check()
  })

  function loadScript() {
    if (document.head.querySelector("#gmaps")) return
    const script = document.createElement("script")
    script.setAttribute('id', "gmaps")
    script.setAttribute('async', "")
    script.setAttribute('defer', "")
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCQ79w13s31_GReC5SQCG_mIEhuLA4DLSs"
    script.setAttribute('type', "text/javascript")
    document.head.appendChild(script);
  }

  function initializeMap() {
    return new google.maps.Map(document.getElementById("map"), {
      zoom: 7,
      center: {lat: 44, lng: 16.5},
      mapTypeId: "satellite",
    });
  }
}

let marker;
function updateMap(map, data, style) {
  setStyle(map, style)
  removeAllFeatures(map)
  if (data.length === 0) {
    map.setZoom(7);
    map.panTo({lat: 44, lng: 16.5})
  }
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    if (d.geometry) {
      const geoj = {type: "Feature", geometry: typeof d.geometry === "string" ? JSON.parse(d.geometry) : d.geometry},
          center = d3.geoPath().centroid(geoj)
      map.panTo({lat: center[1], lng: center[0]})
      map.data.addGeoJson(geoj);
      if (map.getZoom() !== 16) map.setZoom(16);
    } else if (d.point) {
      if (map.getZoom() !== 8) map.setZoom(8);
      map.panTo({lat: d.point[1], lng: d.point[0]})
      marker = new google.maps.Marker({
        position: {lat: d.point[1],
          lng: d.point[0],},
        map,
        title: d.title,
      });
    } else if (d.geometries) {
      const feature_collection = {type: "FeatureCollection", features: []}
      feature_collection.features = d.geometries.map(d => ({type: "Feature", geometry: typeof d === "string" ? JSON.parse(d) : d}))
      const center = d3.geoPath().centroid(feature_collection)
      map.panTo({lat: center[1], lng: center[0]})
      map.data.addGeoJson(feature_collection);
      if (map.getZoom() !== 16) map.setZoom(16);
    }
  }
}

function setStyle(map, style) {
  if (!style) style = {};
  map.data.setStyle({fillColor: style.fillColor || "red", strokeColor: style.strokeColor || "red"})

}

function removeAllFeatures(map) {
  if (marker) marker.setMap(null);
  if (map.data) map.data.forEach(feature => map.data.remove(feature));
}

</script>

<style lang="scss">
.Gmap {
  .map_cont {
    position: relative;
    height: 400px;
    width: 400px;
    max-width: 100%;
    margin: auto;
  }
  #map {
    width: 100%;
    height: 100%;
  }
  .no-map-banner {
    position: absolute;
    height: max-content;
    top: 0;left: 0;bottom: 0;right: 0;
    margin: auto;
  }
}
</style>
