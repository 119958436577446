<template>
  <div class="result_root_cont">

    <div class="card_root">
      <div class="card_ta">
        <div class="close_btn" @click="closeCard">
          <svg-close-x/>
        </div>
        <div class="card_body_ta">
          <template v-if="!hide_coowners_list">
            <div>
              <span class="small-caps" v-html="t.owner_record"></span> <br>
              <div class="mr-5">{{ parcel[zkpl + '_vlasnik'].vlasnik }}</div>
            </div>
            <div class="mt-1">
              <span class="small-caps" v-html="t.location"></span>
              <span>&nbsp;{{ parcel.mainbook }}</span>
            </div>
            <div class="mt-1">
              <span class="small-caps" v-html="t.lr_folio"></span>
              <span>&nbsp;{{ parcel.zk_ulozak }}</span>
            </div>
            <div class="mt-1">
              <div class="row">
                <div class="col-8" @click="openGMap" style="cursor: pointer">
                  <ParcelMapChart :datum="parcel"/>
                </div>
                <div class="col-4" style="position: relative; left: 10px">
                  <div>
                    <span class="small-caps" v-html="t.land_type"></span><br>
                    <img :src="land_type.img" style="width: 60%" :alt="land_type.label">
                  </div>
                  <div class="mt-2">
                    <span class="small-caps" v-html="t.area"></span>
                    <br/>
                    <span class="">{{ parcel[zkpl + '_povrsina'] }} m<sup>2</sup></span>
                  </div>
                  <div class="mt-2">
                    <span class="small-caps" v-html="t.co_owners"></span><br>
                    <span class="">&nbsp;{{ parcel[zkpl + '_suvlasnici'].cnt_tot }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 pr-2">
              <span class="small-caps" v-html="t.co_owners"></span>
              <span>&nbsp;{{ parcel[zkpl + '_suvlasnici'].coo.join(", ") }}</span>
            </div>
          </template>
          <template v-else>
            <div class="owner-w-max-h">
              <span class="small-caps" v-html="t.owner_record"></span> <br>
              <div class="mr-5">{{ parcel[zkpl + '_vlasnik'].vlasnik }}</div>
            </div>
            <div class="position-relative">
              <div class="ferlauf"></div>
            </div>
            <div class="mt-1">
              <div>
                <span class="small-caps" v-html="t.location"></span>
                <span>&nbsp;{{ parcel.mainbook }}</span>
              </div>
              <div style="width: 90%; position: relative;left: 5%">
                <ParcelMapChart :datum="parcel"/>
              </div>
            </div>
            <div class="d-flex text-center position-absolute" style="bottom: 15px">
              <div class="col-4 nowrap">
                <span class="small-caps" v-html="t.land_type"></span><br>
                <img :src="land_type.img" style="width: 80%" :alt="land_type.label">
              </div>
              <div class="col-4 nowrap">
                <span class="small-caps" v-html="t.area"></span>
                <br/>
                <span v-if="parcel.zk_povrsina">{{ parcel.zk_povrsina }} m<sup>2</sup></span>
                <span v-else>N/A</span>
              </div>
              <div class="col-4 nowrap">
                <span class="small-caps" v-html="t.co_owners"></span><br>
                <span class="">&nbsp;{{ parcel[zkpl + '_suvlasnici'].cnt_tot }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="card_footer_ta mt-1">
          <ParcelCardFooter
              :score="parcel.score" :updateParcelScore="updateParcelScore"
              :zkul_in_contract="parcel.zkul_in_contract" :locksmith_include="parcel.locksmith_include"
              :updateLocksmithInclude="updateLocksmithInclude"
          />
        </div>
      </div>
    </div>
  </div>
  <div :style="{display: show_map ? 'block' : 'none'}">
    <Modal :show_modal="true" :closeModal="()=> show_map = false" width="360">
      <Gmap :map_data="map_data" />
    </Modal>
  </div>

</template>

<script>
  import {mapState} from "vuex"

  const t =
    //<i18n>
{
  "en": {
    "owner_record": "owner record in land registry:",
    "location": "location:",
    "land_type": "land type:",
    "area": "area:",
    "co_owners": "co-owners:",
    "lr_folio": "lr folio no.:"
  },
  "hr": {
    "owner_record": "vlasnički zapis u zemljišnim knjigama:",
    "location": "lokacija:",
    "land_type": "tip zemlje:",
    "area": "površina:",
    "co_owners": "suvlasnici:",
    "lr_folio": "br. zk uloška:"
  },
  "es": {
    "owner_record": "proprietario en el registro de la propiedad:",
    "location": "ubicación:",
    "land_type": "tipo:",
    "area": "área:",
    "co_owners": "coproprietarios:"
  }
}
//</i18n>


import ScoreButton from "./elements/ScoreButton";
import ParcelMapChart from "./ParcelCard.MapChart";
import ParcelCardFooter from "./ParcelCard.Footer"
  import Modal from "@/components/Elements/Modal"
  import Gmap from "@/components/Elements/Gmap"
export default {
  name: "ParcelCard",
  components: {Gmap, Modal, ParcelCardFooter, ParcelMapChart, ScoreButton},
  props: ["parcel", "hide_coowners_list"],
  data() {
    return {
      t: t[this.$lang],
      show_map: false,
      map_data: []
    };
  },
  mounted() {
    console.log(this.parcel)
  },
  methods: {
    updateParcelScore(score) {
      this.$store.dispatch("refine_results/updateParcelScore", {datum: this.parcel, score})
      this.closeCard()
    },
    updateLocksmithInclude(value) {
      this.$store.dispatch("refine_results/updateLocksmithInclude", {datum: this.parcel, value})
    },
    closeCard() {
      this.$store.dispatch("refine_results/updateActiveParcel", null)
    },
    openGMap() {
      console.log(this.parcel)
      this.map_data = [];
      if (this.parcel.geometries) this.map_data.push({geometries: this.parcel.geometries})
      this.show_map = true;
    }
  },
  computed: {
    zkpl() {
      return this.parcel.zk_vlasnik ? 'zk' : 'pl'
    },
    ...mapState({
      land_type(state) {
        const vm = this;
        const LAND_TYPES = {
              '1': {code: 'buildings', en: 'Buildings', hr: "Građevine"},
              '2': {code: 'infrastructure', en: 'Infrastructure', hr: "Infrastruktura"},
              '3': {code: 'agricultural_land', en: 'Agricultural Land', hr: "Poljoprivredno zemljište"},
              '4': {code: 'forest_land', en: 'Forest Land', hr: "Šumsko zemljište"},
              '5': {code: 'pasture', en: 'Pasture', hr: "Pašnjaci"},
              '6': {code: 'waters', en: 'Waters', hr: "Vodene površine"},
              '7': {code: 'infertile_land', en: 'Infertile Land', hr: "Neplodna zemljišta"},
              '8': {code: 'other', en: 'Other', hr: "Ostalo"}
            },
            d = vm.parcel,
            land_type_id = d.zk_shlandtypeid || d.pl_shlandtypeid || "8",
            land_type = LAND_TYPES[land_type_id]

        return {
          img: "/static/images/land_types_darker_blue/" + land_type.code + ".svg",
          label: land_type[vm.$lang]
        }
      }
    })
  }
};
</script>

<style scoped>

  .result_root_cont {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    position: relative;
    flex-grow: 2;
  }

  .card_root {
    position: relative;
    background-color: #fff;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 10px 0px 14px 20px;
    overflow-x: hidden;

    font-size: 12px;
    line-height: 1.4;

    border-radius: 5px;
  }

.card_ta {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card_body_ta {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding-bottom: 10px;
}

.ferlauf {
  background: linear-gradient(to top, rgba(252,252,252,1), rgba(252,252,252,0));
  height: 25px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.close_btn {
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 14px;
  color: #e9e8ed;
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  padding: 5px;
}

.nowrap {
  white-space: nowrap;
}

.owner-w-max-h {
  overflow-y: hidden;
  min-height: 28%;
  max-height: 39%;
  padding-bottom: 15px;
}

@media (max-width: 576px) {
  .card_closer_wrapper {
    display: block;
  }
}

</style>
