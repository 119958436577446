<template>
  <svg viewBox="0 0 333.3 333.3" style="enable-background:new 0 0 333.3 333.3;" xml:space="preserve">
    <g v-for="d in region_map">
      <path
        :data-region="d.datum.region"
        :class="{selected: selected[d.datum.region]}"
        :style="{cursor: 'pointer'}"
        @click="onChange(d.datum.region, !selected[d.datum.region])"
        :d="d.path()"
        :transform="`translate(${translateRegions(d.datum.region)})`"
        stroke="none"
      />
    </g>
  </svg>
</template>

<script>
import * as topojson from "topojson";
import cro_counties_polygon_plus from "@/assets/other/cro_counties_polygon_plus__topo__simple.json";
import {PolygonMap} from "@/components/portal/refineResults/charts/PolygonMap"

export default {
  name: "SelectRegion",
  props: ["selected", "onChange"],
  computed: {
    region_map() {
      const vm = this,
          geometry = topojson.feature(
              cro_counties_polygon_plus,
              cro_counties_polygon_plus.objects.cro_counties_polygon_plus
          ),
          dim = {width: 320, height: 320},
          render = props => renderMap({geometry, ...props})

      return PolygonMap({dim, geometry, render})
    }
  },
  methods: {
    translateRegions(region) {
      return region === "W"
          ? '-1, 0'
          : region === "S"
              ? '0, 1'
              : region === "E"
                  ? '1, 0'
                  : region === "N"
                      ? '0, -1'
                      : '0, 0'
    },
  }
}

function renderMap({d3_path, d3_projection, geometry}) {
  return Object.values(geometry.features.reduce(countiesToRegionsReducer, {}))
      .map(d => ({datum: d, path: () => d3_path(d)}));

  function countiesToRegionsReducer(acc, d) {
    const region = getRegion(d.properties.hasc)
    if (!acc.hasOwnProperty(region)) acc[region] = {type: "FeatureCollection", features: [], region}
    acc[region].features.push(d)
    return acc
  }

  function getRegion(hasc) {
    hasc = hasc.toLowerCase().replace("hr.", "")
    return (
        ["is", "pg", "ls"].includes(hasc)
            ? "W"
            : ["zd", "sb", "sd", "dn"].includes(hasc)
            ? "S"
            : ["vs", "ob", "sp", "ps", "vp"].includes(hasc)
                ? "E"
                : ["me", "va", "kz", "kk"].includes(hasc)
                    ? "N"
                    : "M"
    )
  }
}
</script>

<style scoped>
  path {
    transform-origin: 50% 50%;
    fill: #dddced;

    transition: .3s;
    transition-property: fill, opacity;
  }
  path.selected {
    fill: #8cbd4f;
  }
  svg {
    height: 100%;
    width: 100%;
    display: block;
    margin: auto;
  }

  @media (min-width: 576px) {
    path:hover {
      opacity: .8;
    }
  }

</style>