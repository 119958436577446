<template>
  <div>
    <div class="card-hr"></div>
    <div style="padding: 6px 0px 8px">
      <label style="width: 100%;">
          <span class="small display-inline-block pr-1" style="width: 90%">{{ t.include_in_locksmith }}</span>
          <input @input="$event => updateLocksmithInclude($event.target.checked)"
                 :checked="zkul_in_contract || locksmith_include" :disabled="zkul_in_contract"
                 class="d-inline-block ml-2" type="checkbox">
      </label>
    </div>
    <div class="card-hr"></div>
    <div class="row ml-1 mr-1 mt-3 scores-cont">
      <div class="col">
        <ScoreButton :class="{selected: score === 1}" class="score-btn" :score="1" @click.native="updateParcelScore(1)" />
        <div style="color: #41b23b" class="score-label small-caps" v-html="t.probably"></div>
      </div>
      <div class="col text-center">
        <ScoreButton :class="{selected: score === 0}" class="score-btn" :score="0" @click.native="updateParcelScore(0)" />
        <div style="color: #fcc307" class="score-label small-caps" v-html="t.dont_know"></div>
      </div>
      <div class="col text-center">
        <ScoreButton :class="{selected: score === -1}" class="score-btn" :score="-1" @click.native="updateParcelScore(-1)" />
        <div style="color: #d22027" class="score-label small-caps" v-html="t.unprobably"></div>
      </div>
    </div>
  </div>
</template>

<script>
  const t =
    //<i18n>
{
  "en": {
    "probably": "probably",
    "dont_know": "don't know",
    "unprobably": "unprobably",
    "include_in_locksmith": "INCLUDE IN LOCKSMITH AGREEMENT"
  },
  "hr": {
    "probably": "vjerojatno",
    "dont_know": "ne znam",
    "unprobably": "nije vjerojatno",
    "include_in_locksmith": "UKLJUČI U LOCKSMITH UGOVOR"
  },
  "es": {
    "probably": "probablemente",
    "dont_know": "no sé",
    "unprobably": "probablemente no"
  }
}
//</i18n>

  import ScoreButton from "./elements/ScoreButton"
  export default {
    name: "ResultOpenedCardFooter",
    components: {ScoreButton},
    props: ["score", "updateParcelScore", "zkul_in_contract", "locksmith_include", "updateLocksmithInclude"],
    data() {
      return {
        t: t[this.$lang],
      }
    }
  }
</script>

<style scoped>
.card-hr {
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 2px;
  background-color: #bbffff;
}

label {
  margin: 0;
  padding: 0;
}

input[type=checkbox] {
  position: relative;
  display: block;
  width: 15px;
  height: 15px;
  top: 3px
}

.small {
  font-size: 10px;
}


.scores-cont {
  position: relative;
  padding-bottom: 10px;
  height: 50px;
}

.score-label {
  text-align: center;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
}

.score-btn {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin: auto;

  transition: 300ms;
  transform: scale(1);
}

.score-btn:hover, .score-btn.selected {
  transform: scale(1.3);
}
</style>