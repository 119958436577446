import * as d3 from 'd3';
import * as topojson from "topojson";

import cro_counties_polygon_plus from '@/assets/other/cro_counties_polygon_plus__topo__simple.json'

class CroProvincesChart {
  constructor() {
    const CI = this;

    CI.dim = {
      width: 200,
      height: 200
    }
    CI.query_counties = []
  }

  initial(counties, main_group, style, dim) {
    const CI = this;

    CI.query_counties = counties;
    CI.main_group = main_group;
    CI.style = style;

    if (dim) CI.dim = dim

    CI.d3_projection = d3.geoMercator();

    CI.d3_path = d3.geoPath().projection(CI.d3_projection);

    CI.counties = topojson.feature(
      cro_counties_polygon_plus,
      cro_counties_polygon_plus.objects.cro_counties_polygon_plus
    );
    CI.d3_projection.scale(1).translate([0, 0]);

    const w = CI.dim.width, h = CI.dim.height;
    const b = CI.d3_path.bounds(CI.counties);
    const s = .9 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
    const t = [(w - s * (b[1][0] + b[0][0])) / 2, (h - s * (b[1][1] + b[0][1])) / 2];
    CI.d3_projection.scale(s).translate(t);

    CI.update()
  }

  update() {
    const CI = this;


    const counties = CI.main_group.selectAll('.counties').data(CI.counties.features);

    counties.exit().remove();

    const counties_enter = counties.enter()
      .append('path')
      .style("fill", "none")
      .style("stroke-width", ".5px")
      .attr('class', 'counties')

    counties.merge(counties_enter)
      .attr('d', CI.d3_path);
  }

}

export default CroProvincesChart
