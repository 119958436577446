<template>
  <svg
    ref="svg" width="100%"
    viewBox="0 0 245 140" style="enable-background:new 0 0 245 140;" xml:space="preserve"
  >
    <defs>
    </defs>
  </svg>
</template>

<script>
  import * as d3 from 'd3';

  import CroProvincesChart from './charts/CroProvincesChart.js'
  import ParcelsMap from './charts/ParcelsMap.js'

  export default {
    name: "ParcelMapChart",
    props: ["datum"],
    mounted() {
      const vm = this;

      const drawPath = (points, props) => {
        let path_d = "M "+points[0].join()

        for (let i = 1; i < points.length; i++) {
          path_d += " L " + points[i].join(",")
        }

        if (props.isarea === true) path_d += " Z"

        return path_d
      }

      const cro_map_dim = {
        width: 120,
        height: 120,
        offset: {x:0,y:10}
      }

      const parcel_map_dim = {
        width: 105,
        height: 105,
        offset: {x:cro_map_dim.offset.x+cro_map_dim.width+5,y:10}
      }

    d3.select(vm.$refs.svg).select("defs")
        .append("clipPath").attr("id", "map_clip")
        .append("circle").attr("r", parcel_map_dim.width/2)
        .attr("cx", parcel_map_dim.width/2)
        .attr("cy", parcel_map_dim.width/2)

      const g = d3.select(vm.$refs.svg).append("g"),
        datum = vm.datum

      ///////////

      const cro_map_g = g.append("g").attr("transform", "translate(" + cro_map_dim.offset.x + "," + cro_map_dim.offset.y + ")")

      const croProvincesChart = new CroProvincesChart()
      croProvincesChart.initial([], cro_map_g, {}, cro_map_dim)
      cro_map_g.selectAll("path")
        .style("stroke-width", "0px")
        .style("fill", "#e1edf5")
        .style("stroke", "#fff")

      ///////////

      const parcel_map_g = g
        .append("g")
        .attr("transform", "translate(" + (parcel_map_dim.offset.x) + "," + (parcel_map_dim.offset.y) + ")")
        .attr("clip-path", "url(#map_clip)")

      if (datum.geometries.length > 0) {
        const geometry = {type: "FeatureCollection", features: datum.geometries.map(d => ({type: "Feature", geometry: JSON.parse(JSON.stringify(d)), properties: {}}))}
        parcel_map_g.node().innerHTML += ParcelsMap({geometry: geometry, dim: parcel_map_dim})
      } else {
        parcel_map_g
          .append("image")
          .attr("transform", "translate(" + 0 + "," + 0 + ")")
          .attr("width", parcel_map_dim.width).attr("height", parcel_map_dim.height).attr("preserveAspectRatio", "xMidYMid meet")
          .attr("xlink:href", "/static/images/card_na.png")

        parcel_map_g
          .append("text")
          .attr("transform", "translate(" + (parcel_map_dim.width/2-2) + "," + (parcel_map_dim.height/2 + 30/2.7) + ")")
          .style("font-size", 30)
          .style("font-weight", 400)
          .style("fill", "#fff")
          .style("letter-spacing", "1.15")
          .style("text-anchor", "middle")
          .text("N/A")
      }

      //////////
      let ko_centroid = datum.ko_coor
          ? croProvincesChart.d3_projection(typeof datum.ko_coor === "string" ? JSON.parse(datum.ko_coor) : datum.ko_coor)
          : datum.geometries && datum.geometries.length > 0 ? croProvincesChart.d3_path.centroid(datum.geometries[0])
          : null

      if (ko_centroid) {
        const tx_line = {
          d: [
            ko_centroid.map((d,i) => d+cro_map_dim.offset[i?"y":"x"]),
            [parcel_map_dim.offset.x-15, parcel_map_dim.offset.y+parcel_map_dim.height/2],
            [parcel_map_dim.offset.x, parcel_map_dim.offset.y+parcel_map_dim.height/2]
          ]
        }

        g.append("circle")
          .attr("transform", "translate(" + (tx_line.d[0][0]) + "," + (tx_line.d[0][1]) + ")")
          .attr("r", 3)
          .style("fill", "rgb(4,43,93)")

        g.append("path")
          .style("fill", "none")
          .style("stroke", "rgb(4,43,93)")
          .style("stroke-width", "1.5px")
          .attr("d", drawPath(tx_line.d, {}))
      }

      /////////////////
    }
  }
</script>

<style scoped>

</style>
