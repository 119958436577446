import * as d3 from "d3";

export function PolygonMap(props) {
  const d3_projection = center(props.geometry, d3.geoMercator(), props.dim),
    d3_path = d3.geoPath().projection(d3_projection)

  if (props.render) return props.render({d3_path, d3_projection})
  return (`
    <g>
      <path d="${d3_path(props.geometry)}" fill="none" stroke="grey" stroke-linejoin="round" stroke-width="1px" />
    </g>
  `)
}

function center(geojson, d3_projection, dim) {
  d3_projection.scale(1 / (2*Math.PI)).translate([0, 0]);
  const d3_path = d3.geoPath().projection(d3_projection)
  const w = dim.width, h = dim.height;
  const b = d3_path.bounds(geojson);
  let s = (dim.scale || .9) / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  s = Math.min(s, 1 << 24); // max scale
  const t = [(w - s * (b[1][0] + b[0][0])) / 2, (h - s * (b[1][1] + b[0][1])) / 2];
  d3_projection.scale(s/(2*Math.PI)).translate(t);
  return d3_projection
}

