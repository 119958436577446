<template>
  <div class="QuickReportResults blue_bg pt-5 pb-5 position-relative">
    <div class="container">
      <div>
        <div class="text-center">
          <h2><span v-html="t.head1"></span></h2>
          <h5><span v-html="t.underhead1"></span></h5>
        </div>
        <div class="select-region">
          <SelectRegion :selected="selected_regions" :onChange="(k, v) => selected_regions[k] = v" />
        </div>
      </div>
      <div>
        <div class="text-center">
          <h2><span v-html="t.head2"></span></h2>
        </div>
        <div class="parcels_cont" v-if="filtered_parcels.length > 0">
          <Carousel :animate="true" :overflow="true" :key="filtered_parcels">
            <template v-for="d in filtered_parcels">
              <div class="parcel_card_cont carousel_item_cont">
                <div class="parcel_card carousel_item">
                  <ParcelCard :parcel="d" style="overflow-y: hidden" :hide_coowners_list="true" />
                </div>
              </div>
            </template>
          </Carousel>
        </div>
        <div v-else>
          <h4 class="pt-5 pb-5 text-center">NO RESULTS</h4>
        </div>
        <div class="container text-center">
          <p><span v-html="t.about_results1"></span></p>
          <p><span v-html="t.about_results2"></span></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import SelectRegion from "@/components/Elements/SelectRegion"
import Carousel from "@/components/Elements/Carousel"
import ParcelCard from "@/components/portal/refineResults/ParcelCard"

const t =
    //<i18n>
    {
      "en": {

        "head1": "NARROW YOUR RESULTS",
        "underhead1": "Click on the region(s) where person might have had land.",

        "head2": "YOUR SEARCH RESULTS",
        "underhead2": "",

        "about_results1": "The above information is an extract from a full report, providing preliminary results\n" +
            "for land records in the Croatian Registry with a close match to your name.",
        "about_results2": "We can provide you with a free, detailed and fully personalized report, as well as a zero-\n" +
            "obligation option to monetize any valid claim - at no upfront cost to yourself."
      },
      "hr": {
        "head1": "NARROW YOUR RESULTS",
        "underhead1": "Click on the region(s) where person might have had land.",

        "head2": "YOUR SEARCH RESULTS",
        "underhead2": "",
      },
      "es": {

      }
    }
//</i18n>

export default {
name: "QuickReportResults",
  components: {ParcelCard, Carousel, SelectRegion},
  props: ["parcels"],
  data() {
    return {
      t: t[this.$lang],
      selected_regions: {}
    }
  },
  mounted() {
    const vm = this;
    vm.selected_regions = vm.parcels.reduce((acc, d) => {acc[regijaidTranslate(d.regijaid, true)] = true; return acc}, {})
  },
  computed: {
    filtered_parcels() {
      const vm = this;
      return vm.parcels.filter(d => vm.selected_regions[regijaidTranslate(d.regijaid, true)])
    }
  },
}

function regijaidTranslate(key, fromRegijaid) {
  return  [[1, 'M'], [2, 'E'], [3, 'S'], [4, 'W'], [5, 'N']].find(d => (d[fromRegijaid ? 0 : 1]) === key)[fromRegijaid ? 1 : 0]
}
</script>

<style lang="scss">
  .QuickReportResults {
    overflow: hidden;
    .container {
      overflow: visible;
    }

    .select-region {
      width: 350px;
      max-width: 100%;
      margin: auto;
    }

    .parcel_card_cont {
      display: inline-block;
    }

    .parcel_card {
      width: 250px;
      height: 350px;
      overflow-y: hidden;
      color: rgb(4,43,93);
      position: relative;
      white-space: normal;

      display: inline-flex;
      flex-direction: column;


      .card_root {
        font-size: 14px!important;
      }

      .close_btn {
        display: none;
      }
      .card_footer_ta {
        display: none;
      }
      .card_body_ta {
        overflow-y: hidden;
      }
      .col-4 {
        padding-left: 0;
      }
      .col-8 {
        padding-right: 0;
      }
    }

    @media (max-width: 576px) {
      .container {
        margin: 0;
        padding: 0;
      }
    }
  }

</style>