import {geoMercator, geoPath} from 'd3-geo';
import d3_tile from '@/assets/plugins/d3Tiles.js'

export default function ParcelsMap(props) {
  const d3_projection = center(props.geometry, geoMercator(), props.dim),
    d3_path = geoPath().projection(d3_projection),
    tiles = get(d3_projection, props.dim),
    tiles_elements = tiles.map(d => (`
      <image xlink:href="${d.image_path}" x="${d.x0}" y="${d.y0}" width="256" height="256" />
    `))

  return (`
    <g>
      ${tiles_elements}
      <path d="${d3_path(props.geometry)}" fill="none" stroke="red" stroke-linejoin="round" stroke-width="2px" />
    </g>
  `)

}


function setupTiles(tiles, tile) {
  tile.x0 = (tile.x + tiles.translate[0]) * 256;
  tile.y0 = (tile.y + tiles.translate[1]) * 256;

  const {x, y, z} = tile,
      url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        .replace("{s}", "abc"[x % 3]).replace("{z}", z).replace("{x}", x).replace("{y}", y)

  tile.image_path = url
  return tile
}

function get(projection, {width, height}) {
  const tiles = d3_tile()
    .size([width, height])
    .scale(projection.scale() * (2 * Math.PI))
    .translate(projection([0, 0]))();

  tiles.forEach(tile => setupTiles(tiles, tile))

  return tiles
}

function center(geojson, d3_projection, dim) {
  d3_projection.scale(1 / (2*Math.PI)).translate([0, 0]);
  const d3_path = geoPath().projection(d3_projection),
    w = dim.width, h = dim.height,
    b = d3_path.bounds(geojson);
  let s = .7 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  s = Math.min(s, 1 << 24); // max scale
  const t = [(w - s * (b[1][0] + b[0][0])) / 2, (h - s * (b[1][1] + b[0][1])) / 2];

  d3_projection.scale(s/(2*Math.PI)).translate(t);
  return d3_projection
}
