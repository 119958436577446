<template>
  <div class="LandingPageUSA">
    <div>
      <LandingPageTopParallax />
      <div class="root_cont">
        <div class="container">
          <div class="text-center">
            <h1 class="mb-4"><span v-html="t.head1"></span></h1>
            <h5><span v-html="t.underhead1"></span></h5>
          </div>
          <div>
            <form @submit="submit($event)">
              <div class="search_cont">
                <input type="text" class="query" name="query" required :placeholder="t.placeholder1" v-model="query">
                <input type="text" class="code" name="code" required :placeholder="t.placeholder2" v-model="code">
                <button class="btn" type="submit" data-w="lp-search-search">{{ t.search }}</button>
              </div>
            </form>
          </div>
          <div class="text-center">
            <img src="/static/images/ZastaviceHrUS.png" width="150" alt="">
            <div class="mt-4">
              <span v-html="t.code_no_valid_1"></span><br>
              <i><span v-html="t.code_no_valid_2"></span></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blue_bg position-relative">
      <div ref="your_results_anchor" style="position: absolute; top: -100px"></div>
      <div v-if="loading" style="padding: 200px 0" class="text-center">
        <LoaderAnim />
      </div>
      <QuickReportResults v-if="parcels" :parcels="parcels" />
    </div>
    <QuickReportRegistration/>

    <Modal :show_modal="show_modal" :closeModal="closeModal" :no_close_btn="true" width="350">
      <div class="text-center pt-2 pb-2">
        <img v-if="no_full_name" src="/static/images/svg/ImePrezime.svg" alt="ImePrezime" style="width: 100px">
        <img v-else-if="code_invalid || code_used" src="/static/images/svg/InvalidCode.svg" alt="InvalidCode" style="width: 100px">
        <img v-else-if="no_results" src="/static/images/svg/NoResults.svg" alt="NoResults" style="width: 75px">
        <div class="mt-3" style="font-size: 24px">
          <span v-if="no_full_name">Please enter name and surname</span>

          <span v-else-if="code_used">Used code!</span>
          <span v-else-if="code_invalid">Invalid code!</span>
          <div v-else-if="no_results">
            <p>No results!</p>
            <p style="font-size: 16px;font-weight: 400;">Register for advanced search</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import LoaderAnim from "@/components/Elements/loader-anim"
const t =
    //<i18n>
    {
      "en": {
        "head1": "Welcome to Terra Adriatica",
        "underhead1": "Enter your, (or a family member’s), name and your one-time code\n" +
            "to search for possible matches to Croatian Land records.",
        "placeholder1": "Enter first and last name",
        "placeholder2": "One-Time Code",
        "search": "SEARCH",
        "code_no_valid_1": "Code no longer valid? No Problem Scroll down to register and access your full report.",
        "code_no_valid_2": "You can unsubscribe at any time.",
      },
      "hr": {
        "head1": "Welcome to Terra Adriatica",
        "underhead1": "Enter your, (or a family member’s), name and your one-time code\n" +
            "to search for possible matches to Croatian Land records.",
        "placeholder1": "Enter first and last name",
        "placeholder2": "One-Time Code",
        "search": "SEARCH",
        "code_no_valid_1": "Code no longer valid? No Problem Scroll down to register and access your full report.",
        "code_no_valid_2": "You can unsubscribe at any time.",
      },
      "es": {}
    }
//</i18n>

import {TweenLite, Linear} from 'gsap'
import {RoughEase} from 'gsap/EasePack'

import {apiFetchGetNoAuth} from "@/api/handler"
import {groupParcels} from "@/store_vuex/handlers/refine_results.structureData"
import QuickReportResults from "@/components/web/other/QuickReportResults"
import QuickReportRegistration from "@/components/web/other/QuickReportRegistration"
import Modal from "@/components/Elements/Modal"
import LandingPageTopParallax from "../../components/Elements/LandingPageTopParallax"


export default {
  name: "LandingPageUSA",
  components: {LandingPageTopParallax, LoaderAnim, Modal, QuickReportRegistration, QuickReportResults},
  data() {
    return {
      t: t[this.$lang],
      query: '',
      code: '',
      loading: false,

      code_invalid: false,
      code_used: false,
      no_results: false,
      no_full_name: false,

      parcels: null
    }
  },
  mounted() {
    const q = this.$route.query
    if (q.query) this.query = q.query
    if (q.code) this.code = q.code
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.closeModal();
      if (this.query.indexOf(" ") === -1) {
        this.no_full_name = true
        return
      }
      const form_data = new FormData(e.target),
          params = `?query=${form_data.get("query")}&code=${form_data.get("code")}`
      this.getParcels(params)
    },
    getParcels(params) {
      const vm = this;
      vm.parcels = null;
      vm.loading = true;
      apiFetchGetNoAuth("/api/portal/landing-page-parcels-fetch" + params)
          .then(r => r.json())
          .then(data => {
            if (data.error) {
              if (data.error === 'invalid onetimecode') vm.code_invalid = true;
              if (data.error === 'onetimecode used') vm.code_used = true;
              return
            } else if (!data.tree_parcels) {
              this.no_results = true;
              return
            }
            data = adjustData(data)
            vm.parcels = data
          })
          .finally(() => vm.loading = false)

      function adjustData(data) {
        data.tree_parcels[0].data.forEach(d => {
          d.geometries = d.geometry ? [JSON.parse(d.geometry)] : []
          d.regijaid = d.zk_regijaid
        })
        return data.tree_parcels[0].data
      }
    },
    closeModal() {
      this.no_full_name = this.code_used = this.code_invalid = this.no_results = false
    }
  },
  watch: {
    show_modal() {
      if (this.show_modal)
        redoMaybeInIntervalForTime({f: () => this.$el.querySelector(".modal .modal-body")})
          .then(el => shake(el).then(() => fadeOutToCallback(el, this.closeModal, this.no_full_name ? 3 : 5)))
    },
    loading() {
      if (this.loading) this.$refs.your_results_anchor.scrollIntoView({behavior: "smooth"})
    }
  },
  computed: {
    show_modal() {
      return this.code_invalid || this.code_used || this.no_results || this.no_full_name
    }
  }
}

function shake(el) {
  return new Promise(resolve => {
    TweenLite.fromTo(el, 0.3, {x: -2}, {
      x: 2, ease: RoughEase.ease.config({strength: 8, points: 20, template: Linear.easeNone, randomize: false}),
      clearProps: "x", onComplete: () => resolve()
    })
  })
}

function fadeOutToCallback(el, callback, delay=0) {
  TweenLite.to(el, .5, {alpha: 0, delay, onComplete: callback})
}

function redoMaybeInIntervalForTime({f, interval_time = 200, timeout_time = 1000}) {
  let timeout = false;
  setTimeout(() => timeout = true, timeout_time)
  return new Promise(resolve => {
    check()

    function check() {
      const result = f();
      if (result) resolve(result)
      else if (timeout) resolve(false)
      else setTimeout(check, interval_time);
    }
  })
}

</script>

<style lang="scss">
.LandingPageUSA {
  .root_cont {
    color: #fff;
    padding: 40px 0;
    position: relative;
  }

  h1 {
    width: 430px;
    max-width: 95%;
    margin: auto;

    font-size: 3.5rem;
    color: #e4c34f;
    font-weight: 200 !important;
  }

  h5 {
    width: 580px;
    max-width: 95%;
    margin: auto;

    font-weight: 400 !important;
  }

  .search_cont {
    margin: 40px auto 50px;
    width: 650px;
    max-width: 100%;

    input {
      border: 0;
      outline: 0;
      padding-left: 10px;
    }

    input.query {
      height: 60px;
      width: 55%;

      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    input.code {
      height: 60px;
      width: 23%;
      margin-left: 1%;
    }

    .btn {
      color: #fff;
      padding: 17px;
      background-color: #e4c34f;

      margin-top: -3.5px;
      margin-left: 1%;
      height: 60px;
      width: 20%;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    @media (max-width: 576px) {
      input.query {
        width: 63%;
      }
      input.code {
        width: 35%;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
      .btn {
        margin-left: 10%;
        margin-top: 3px;
        width: 80%;
        border-radius: 8px;
      }
    }
  }

}


</style>
