<template>
  <div class="position-relative blue_bg" style="overflow: hidden">
      <div class="parallax" :style="{
      'background-image': 'url(\'' + '/static/images/KomizaGridtamna.jpg' + '\')',
      'background-position-y': '50%',
      }">
    <div class="container pt-5 position-relative">
        <div class="text-center mb-5">
          <h2 class="mb-0"><span v-html="t.head1"></span></h2>
          <h5><i><span v-html="t.underhead1"></span></i></h5>
        </div>
        <div class="row container pb-5">
          <div class="col-12 col-md-5 text-center">
            <img
                src="/static/images/ReportTiltedToRight.png"
                alt="TA Report"
            />
            <a target='_blank' :href="`/${$lang}/free_report`" class="btn goldout btn-down-center mt-4"><span v-html="t.VIEW_SAMPLE"></span></a>
          </div>
          <div class="col-12 col-md-7">
            <form @submit="submit($event)">
              <input type="hidden" name="language" :value="$lang"/>
              <div class="input_cont"><input name="first_name" type="text" :placeholder="t.name" required></div>
              <div class="input_cont"><input name="last_name" type="text" :placeholder="t.last_name" required></div>
              <div class="input_cont">
                <select name="country_of_residence" :placeholder="t.country" required>
                  <option value="" disabled selected>{{t.country}}</option>
                  <option v-for="d in countries" :value="d.abbreviation"
                  >{{ d.country }}
                  </option>
                </select>
              </div>
              <div class="input_cont">
                <input name="email" type="text" :placeholder="t.mail_address" required>
                <span v-for="err in errors.email" class="inp_error">{{err}}</span>
              </div>


              <div class="form-check mt-2">
                <input type="checkbox" class="form-check-input" id="check1" name="subscribed_for_notifications"/>
                <label class="form-check-label" for="check1">
                  <span class="help_text"><span v-html="t.subscribe_text_1"></span> <i><span v-html="t.subscribe_text_2"></span></i></span>
                </label>
              </div>

              <div class="form-check mt-2">
                <input type="checkbox" class="form-check-input" id="check2" v-model="checkbox_pos_tos" />
                <label class="form-check-label" for="check2">
                  <span class="help_text">
                    <span v-html="t.tos_text_1"></span>&nbsp;
                    <router-link :to="{ name: 'privacy_policy' }"><span v-html="t.tos_text_2"></span></router-link>&nbsp;
                    <span v-html="t.tos_text_3"></span>&nbsp;
                    <router-link :to="{ name: 'tos' }" target="_blank"><span v-html="t.tos_text_4"></span></router-link>
                  </span>
                </label>
                <span v-for="err in errors.checkbox_pos_tos" class="inp_error">{{ err }}</span>
              </div>

              <div class="text-center mt-4">
                <button class="btn goldin btn-down-center" data-w="lp-search-register"><span v-html="t.CONTINUE"></span></button>
              </div>
            </form>
          </div>
        </div>

      </div>
    <Modal :show_modal="show_modal" :closeModal="()=>show_modal=false">
      <div v-html="modal_body"></div>
    </Modal>
      </div>

  </div>
</template>

<script>

import {register} from "@/api/auth"
import countries from "@/assets/other/countries.json"
import Modal from "@/components/Elements/Modal"

const t =
    //<i18n>
    {
      "en": {
        "head1": "To Access your free and fully personalized\n" +
            "report, please complete your details.",
        "underhead1": "You can unsubscribe fully at any time.",

        "VIEW_SAMPLE": "VIEW SAMPLE",

        "name": "Name",
        "last_name": "Last Name",
        "country": "Country",
        "mail_address": "E-mail",

        "subscribe_text_1": "Please keep me up to date with relevant information pertaining to my possible claim to land.",
        "subscribe_text_2": "(You can unsubscribe at any time)",
        "tos_text_1": "By creating an account, I agree to Terra Adriatica’s",
        "tos_text_2": "Privacy policy",
        "tos_text_3": "and",
        "tos_text_4": "Terms of Use",

        "CONTINUE": "CONTINUE",

        "tos_error": "Please agree to our Privacy Policy and Terms of Use to continue.",
        "A user with that email already exists.": "A user with that e-mail already exists.",

        "check_mail_1": "Almost there!",
        "check_mail_2": "To finish your registration, please check your mailbox. A confirmation e-mail was sent to your e-mail address.",
        "check_mail_3": "In case you can't find it, please check also in spam/junk folder.",
      },
      "hr": {
        "head1": "To Access your free and fully personalized\n" +
            "report, please complete your details.",
        "underhead1": "You can unsubscribe fully at any time.",

        "name": "Ime",
        "last_name": "Prezime",
        "country": "Država",
        "mail_address": "E-mail adresa",

        "VIEW_SAMPLE": "VIEW SAMPLE",

        "subscribe_text": "Molim da me obavještavate o povremenim ažuriranjima proizvoda i usluga Terra Adriatice.",
        "tos_text_1": "Izradom računa prihvaćam",
        "tos_text_2": "Politiku privatnosti",
        "tos_text_3": "i",
        "tos_text_4": "Uvjete korištenja",

        "CONTINUE": "NASTAVI",

        "A user with that email already exists.": "E-mail adresa je već postojeća.",
        "tos_error": "Molimo potvrdite slažete li se s našom Politikom privatnosti i Uvjetima korištenja.",

        "check_mail_1": "Korak ste do cilja!",
        "check_mail_2": "Da biste završili registraciju, molimo Vas da provjerite svoj e-mail. Na Vašu adresu poslan je e-mail potvrde.",
        "check_mail_3": "U slučaju da ga ne možete pronaći, provjerite u spam pretincu.",
      },
      "es": {

      }
    }
//</i18n>

export default {
  name: "QuickReportRegistration",
  components: {Modal},
  data() {
    return {
      t: t[this.$lang],

      show_modal: false,
      modal_body: '',
      checkbox_pos_tos: false,
      account_created: false,
      countries: countries,

      errors: {
        email: [],
        checkbox_pos_tos: [],
      },
    }
  },
  watch: {
    show_modal() {
      const vm = this;
      if (!vm.show_modal && vm.account_created)
        vm.$store.dispatch("auth/changeModalStatus", false);
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();
      const vm = this;


      if (!vm.checkbox_pos_tos) {
        vm.errors.checkbox_pos_tos = [vm.t.tos_error];
        return;
      }

      register(e).then(response => {
        if (response.status === 200) {
          response.json().then(data => {
            console.log(data)
            vm.modal_body =
                "<h4 class='font-weight-normal mb-3'>" + vm.t.check_mail_1 + "</h4>" +
                "<p>" + vm.t.check_mail_2 + "</p>" +
                "<p>" + vm.t.check_mail_3 + "</p>";
            vm.show_modal = true;
            vm.account_created = true;
            window.history.pushState({}, "welcome", "/welcome")
          });
        } else if (response.status === 400) {
          response.json().then(data => {
            console.log(data)
            vm.modal_body = "";
            Object.keys(vm.errors).forEach(k => (vm.errors[k] = []));
            Object.keys(data.errors).forEach(error_type => {
              vm.errors[error_type] = data.errors[error_type].map(
                  err_code => vm.t[err_code]
              );
            });
          });
        } else {
        }
      });

    }
  }
}
</script>

<style scoped>

.parallax {
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

h2 {
  font-weight: 400!important;
}

.row > div {
  position: relative;
  padding-bottom: 80px;
}

img {
  width: 100%;
}

.input_cont {
  margin-bottom: 20px;
}

input, select {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 10px;
  border-radius: 2px;

  background-color: #fff!important;
}

select {
  padding-left: 5px;
}

input, select, select option {
  color: rgb(4,43,93);
}

select:invalid, select option:first-child { color: gray; }

input[type="checkbox"] {
  width: 13px;
  height: 13px;
  margin-top: 6px;
}

label.form-check-label {
  line-height: 1.2;
  margin-left: 5px;
}

.help_text {
  font-size: 14px;
}

a.btn {text-decoration: none}

.btn {
  width: 90%;
  padding: 10px;
  transition: .6s;
}

.btn-down-center {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 10%;
}

.btn.goldin {
  width: 80%;
  border: #e4c34f solid 1.5px;
  background-color: #e4c34f;
  color: #fff;
}

.btn.goldin:hover {
  color: #e4c34f!important;
  background-color: transparent;
}

.btn.goldout {
  border: #e4c34f solid 1.5px;
  background-color: transparent;
  color: #e4c34f;
}

.btn.goldout:hover {
  color: #fff!important;
  background-color: #e4c34f;
}

.inp_error {
  display: block;
  color: red;
  font-size: 11px;
}

@media (max-width: 576px) {
  .row > div {
    padding-bottom: 30px;
  }
  .btn-down-center {
    position: static;
  }
}


</style>