<template>
  <div class="btn_root" :class="{unselected: !!unselected}">
    <template v-if="score === -1">
      <svg viewBox="0 0 106.98365 106.98365" style="enable-background:new 0 0 106.98365 106.98365;" xml:space="preserve" class="unlikely">
        <circle cx="53.491825" cy="53.491825" r="53.491825"/>
        <path d="M59.148273,53.491837l19.406254-19.40625c1.5625-1.561523,1.5625-4.094727,0-5.65625
          c-1.560547-1.5625-4.095703-1.5625-5.65625,0l-19.406254,19.40625l-19.40625-19.40625c-1.560547-1.5625-4.095701-1.5625-5.656248,0
          c-1.5625,1.561523-1.5625,4.094727,0,5.65625l19.406248,19.40625l-19.406248,19.40625c-1.5625,1.561523-1.5625,4.094727,0,5.65625
          c0.780273,0.78125,1.804688,1.171875,2.828125,1.171875c1.023436,0,2.04785-0.390625,2.828123-1.171875l19.40625-19.40625
          l19.406254,19.40625c0.780273,0.78125,1.804688,1.171875,2.828125,1.171875s2.047852-0.390625,2.828125-1.171875
          c1.5625-1.561523,1.5625-4.094727,0-5.65625L59.148273,53.491837z"/>
      </svg>
    </template>
    <template v-else-if="score === 0">
      <svg viewBox="0 0 106.98365 106.98365" style="enable-background:new 0 0 106.98365 106.98365;" xml:space="preserve" class="possibly">
        <circle cx="53.491825" cy="53.491825" r="53.491825"/>
        <path d="M53.772991,81.522789c-1.997639,0-3.577576-0.580849-4.830147-1.775467
          c-1.236206-1.177795-1.811417-2.515877-1.811417-4.21209c0-1.731628,0.552582-3.047707,1.739143-4.141785
          c1.230122-1.131645,2.834476-1.682793,4.90242-1.682793c2.075096,0,3.698502,0.555977,4.962704,1.699608
          c1.200512,1.089424,1.761326,2.401207,1.761326,4.124969c0,1.696037-0.575302,3.034119-1.810699,4.211197
          C57.431782,80.941582,55.824654,81.522789,53.772991,81.522789z M57.645374,59.940521
          c0.669754,0,1.274036-0.417522,1.580112-1.096939c0.286041-0.629684,0.650436-1.230747,1.084419-1.787624
          c0.985847-1.263126,2.438061-2.707558,4.439636-4.415039c1.61142-1.405788,2.923737-2.665157,3.902252-3.745014
          c0.869576-0.957855,1.639687-2.183502,2.287621-3.639648c0.611794-1.379761,0.922165-3.020073,0.922165-4.875317
          c0-3.9739-1.568489-6.976082-4.795265-9.178102c-3.496181-2.38636-8.271408-3.596357-14.19286-3.596357
          c-5.325756,0-9.901524,0.974581-13.600475,2.896908c-0.839699,0.436129-1.648182,0.920288-2.403179,1.438883
          c-1.110714,0.762957-1.74773,1.970625-1.74773,3.313269c0,1.51178,0.792381,2.839844,2.119549,3.552441
          c0.625751,0.335953,1.329407,0.513496,2.034939,0.513496c0.949802-0.000088,1.863113-0.323696,2.574192-0.91349
          c0.598648-0.495159,1.240677-0.943542,1.905872-1.331192c2.166775-1.265987,4.601795-1.907925,7.237438-1.907925
          c2.662922,0,4.916458,0.643368,6.702293,1.915619c2.019821,1.445499,3.087425,3.489918,3.087425,5.912327
          c0,1.720184-0.562603,3.334465-1.670811,4.796246c-0.86063,1.1371-2.10873,2.462929-3.818718,4.056011
          c-2.136814,1.984669-3.76791,3.800648-4.847855,5.3974l-0.046421,0.068516c-0.470207,0.735943-0.194988,1.42609-0.053131,1.691025
          c0.150444,0.281033,0.602585,0.934509,1.550331,0.934509H57.645374z"/>
        </svg>
    </template>
    <template v-else-if="score === 1">
      <svg viewBox="0 0 106.98365 106.98365" style="enable-background:new 0 0 106.98365 106.98365;" xml:space="preserve" class="probably">
        <circle cx="53.491825" cy="53.491825" r="53.491825"/>
        <g>
          <path d="M43.916985,79.951096c-1.061035,0-2.079102-0.421875-2.830078-1.172852L20.930658,58.59758
            c-1.561035-1.563477-1.55957-4.095703,0.003418-5.657227c1.5625-1.561523,4.095215-1.560547,5.656738,0.00293l17.24658,17.267578
            l36.960938-39.124023c1.51709-1.606445,4.049805-1.676758,5.654785-0.160156
            c1.605469,1.516602,1.677734,4.047852,0.160645,5.654297L46.824699,78.698166
            c-0.742188,0.786133-1.770996,1.237305-2.852051,1.25293C43.954094,79.951096,43.935539,79.951096,43.916985,79.951096z"/>
        </g>
      </svg>
    </template>
    <template v-else>
      <svg viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
        <circle style="stroke: #e9e8ed; stroke-width: 4; fill: #fff" cx="50" cy="50" r="48"/>
        <circle style="fill: #e9e8ed" cx="50" cy="50" r="4"/>
        <circle style="fill: #e9e8ed" cx="30" cy="50" r="4"/>
        <circle style="fill: #e9e8ed" cx="70" cy="50" r="4"/>
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: "ScoreButton",
  props: ["score", "unselected"]
};
</script>

<style scoped lang="scss">
.btn_root {
  cursor: pointer;
}
path {
  fill: #fff;
}

.unlikely {
  circle {fill: #d22027;}
}

.possibly {
  circle {fill: #fcc307}
}
.probably {
  circle {fill: #41b23b}
}

.unselected {
  circle {
    transform: scale(.96);
    transform-origin: 50% 50%;
    stroke: #9faec1;
    stroke-width: 5;
    fill: none;
  }
  path {
    fill: #9faec1;
  }
}
</style>
